<template>
  <el-card>
    <div slot="header">MY DAILY TIME RECORD</div>
    <el-dialog title="Holidays" :visible.sync="dialogHoliday">
      <el-table :data="holidays" border>
        <el-table-column label="Holiday Name" prop="name"></el-table-column>
        <el-table-column label="Declaration" prop="is_nationwide">
          <template slot-scope="scope">
            {{ scope.row.is_nationwide === '1' ? 'Nationwide Holiday' : 'Local Holiday' }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="File Application" :visible.sync="dialogFA">
      <el-table :data="fileapplication" border>
        <el-table-column label="File Type" prop="bft_id_name"></el-table-column>
        <el-table-column label="Date" prop="date_dates"></el-table-column>
        <el-table-column label="Time From" prop="time_from"></el-table-column>
        <el-table-column label="Time To" prop="time_to"></el-table-column>
      </el-table>
    </el-dialog>
    <el-calendar v-model="date" :first-day-of-week="7">
      <template slot="dateCell" slot-scope="{date}">
        <el-container>
          <p class="day">{{ date.getDate() }}</p>
          <div v-if="calendarData">
            <el-button v-if="getHolidays(date)" class="holiday" type="success" size="mini" @click="getHoliday(date)">Holidays</el-button>
            <el-button v-if="getFileApplicationAID(date)" class="file" type='primary' size="mini" icon="el-icon-edit-outline" @click="getFileApplication(date)" circle></el-button>
            <div v-if="getTimekeepData(date)" class="tito">
              <p>Work Schedule</p>
              <el-row>
                <el-col :span="12">
                  <p class="type">From</p>
                  <p v-if="getWorkShift(date, 'from')">{{ getWorkShift(date, 'from') }}</p>
                </el-col>
                <el-col :span="12">
                  <p class="type">To</p>
                  <p v-if="getWorkShift(date, 'to')">{{ getWorkShift(date, 'to') }}</p>
                </el-col>
              </el-row>
              <p>Time Record</p>
              <el-row>
                <el-col :span="12">
                  <p class="type">In</p>
                  <p v-if="getLogs(date, 'in')">{{ getLogs(date, 'in') }}</p>
                </el-col>
                <el-col :span="12">
                  <p class="type">Out</p>
                  <p v-if="getLogs(date, 'out')">{{ getLogs(date, 'out') }}</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-container>
      </template>
    </el-calendar>
  </el-card>
</template>

<script>
import { mapState } from 'vuex'
import { format } from 'date-fns'
export default {
  name: 'DTR',
  data () {
    return {
      date: new Date(),
      calendarData: null,
      employeeId: null,
      holidays: [],
      dialogFA: false,
      dialogHoliday: false,
      fileapplication: []
    }
  },
  mounted () {
    this.employeeId = this.user.employeeId
    this.getCalendarData(this.date)
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    date (newData, oldData) {
      if (newData.getMonth() !== oldData.getMonth()) {
        this.getCalendarData(this.date)
      }
    }
  },
  methods: {
    getHolidays (date) {
      return this.calendarData[format(date, 'MM/dd/yyyy')] ? this.calendarData[format(date, 'MM/dd/yyyy')].holiday.length : false
    },
    getFileApplicationAID (date) {
      return this.calendarData[format(date, 'MM/dd/yyyy')] ? this.calendarData[format(date, 'MM/dd/yyyy')].fileapplication.length : false
    },
    getTimekeepData (date) {
      return this.calendarData[format(date, 'MM/dd/yyyy')] ? this.calendarData[format(date, 'MM/dd/yyyy')].timekeep_data.logdate : false
    },
    getFileApplication (date) {
      this.fileapplication = this.calendarData[format(date, 'MM/dd/yyyy')].fileapplication
      this.dialogFA = true
    },
    getHoliday (date) {
      this.holidays = this.calendarData[format(date, 'MM/dd/yyyy')].holiday
      this.dialogHoliday = true
    },
    getWorkShift (date, type) {
      if (type === 'from') {
        return this.calendarData[format(date, 'MM/dd/yyyy')].timekeep_data.work_shift_from
      } else {
        return this.calendarData[format(date, 'MM/dd/yyyy')].timekeep_data.work_shift_to
      }
    },
    getLogs (date, type) {
      if (type === 'in') {
        return this.calendarData[format(date, 'MM/dd/yyyy')].timekeep_data.logtime_in
      } else {
        return this.calendarData[format(date, 'MM/dd/yyyy')].timekeep_data.logtime_out
      }
    },
    openDetails (file, day) {
      let approverText = ''
      if (file.nextApprover) {
        approverText = `The next approver is ${file.nextApprover}`
      }
      if (file.disapprovedBy) {
        approverText = `Disapproved by ${file.disapprovedBy}`
      }
      this.$alert(`Your leave for this date is ${file.status}. ${approverText}`, {
        title: `Leave for ${day}`
      })
    },
    getCalendarData (date) {
      const range = this.getDateRange(date)
      const form = {
        em_id: this.employeeId,
        date_from: range.f,
        date_to: range.l
      }
      this.$http
        .post('timekeep.app/dtr-list', form)
        .then(res => {
          this.calendarData = res.data.body
        })
    },
    getDateRange (date) {
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      firstDay = this.formatDate(firstDay)
      lastDay = this.formatDate(lastDay)
      return {
        f: firstDay,
        l: lastDay
      }
    },
    formatDate (date) {
      return format(date, 'MM/dd/yyyy')
    }
  }
}
</script>

<style lang="scss">
  .el-calendar-day {
  height: 200px !important;
  overflow: auto;
  cursor: default !important;
  .el-container {
    position: relative;
    height: 100%;
    width: 100%;
    .day {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 20px;
      height: 20px;
    }
    > div {
      position: relative;
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .holiday {
      position: absolute;
      top: 0;
    }

    .file {
      position: absolute;
      top: 0;
      right: 0;
    }

    .tito {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #000;

      .el-row {
        width: 100%;
      }
      p {
        margin: 0;
        font-size: 12px;
        text-align: center;

        &.type {
          color: rgb(138, 138, 138);
        }
      }
    }

    .el-tag {
      cursor: pointer;
    }
  }
}
.el-calendar-table td.is-selected {
  background-color: transparent;
}
</style>
